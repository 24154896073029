<template>
    <div class="WmaCostPriceDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form label-width="200px" :model="detail" size="small">
                <el-form-item label="机构类型">
                    <el-input readonly v-model="detail.deptTypeName" />
                </el-form-item>
                <el-form-item label="机构名称">
                    <el-input readonly v-model="detail.deptName" />
                </el-form-item>
                <el-form-item label="商品名称">
                    <el-input readonly v-model="detail.goodsName" />
                </el-form-item>
                <el-form-item label="库存">
                    <el-input readonly v-model="detail.stock" />
                </el-form-item>
                <el-form-item label="旧的移动加权成本单价(元)">
                    <el-input readonly v-model="detail.oldStockCostPrice" />
                </el-form-item>
                <el-form-item label="新的移动加权成本单价(元)">
                    <el-input readonly v-model.number="detail.newStockCostPrice" />
                </el-form-item>
                <el-form-item label="差异总计(元)">
                    <el-input readonly v-model.number="detail.wmaCostDiff" />
                </el-form-item>
            </el-form>
            <el-button type="primary" @click="handleAudit" v-if="auditDetail" size="small">审核详情</el-button>

            <PickupReturnAudit
                ref="PickupReturnAudit"
                :have-reject="false"
                :have-review="false"
                review-privilege-flag="menu.goods.wma.costPrice.review"
            />
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import PickupReturnAudit from '../../../components/PickupReturnAudit';
import Util from 'js/Util';

export default {
    name: 'WmaCostPriceDetail',
    components: { PickupReturnAudit },
    props: {
        code: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            baseUrl: '/goods/wma/costPrice',
            auditDetail: false,
            detail: {
                code: '',
                deptTypeName: '',
                deptName: '',
                goodsName: '',
                stock: null,
                oldStockCostPrice: null,
                newStockCostPrice: null,
                wmaCostDiff: null,
            },
        };
    },

    mounted() {
        //查询单据详情
        (async () => {
            const rst = await this.$efApi.wmaCostPriceChangeApi.detail(this.code);
            Object.keys(this.detail).forEach((key) => {
                this.detail[key] = rst[key];
            });
            this.detail.wmaCostDiff = Util.multiply(
                Util.subtract(this.detail.newStockCostPrice, this.detail.oldStockCostPrice),
                this.detail.stock
            );
        })();
        UrlUtils.QueryRemote(this, `${this.baseUrl}/reviewListData/` + this.code, (rst) => {
            if (rst.reviewList.length > 0) {
                this.auditDetail = true;
            }
        });
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.code, this.baseUrl);
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
